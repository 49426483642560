<script setup>
import Content from '@/views/Layouts/Content.vue'
import Mystery from '@/components/Mystery/Mystery.vue'

import { reactive, ref, onMounted, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import { getMysteryTitle } from '@/utils'

const route = useRoute()
const router = useRouter()
const store = useStore()

const mystery = reactive(ref(store.getters['mystery/GET_ITEM'](route.params.id)))

const formMystery = ref(null)
const v$ = reactive(ref({}))

watchEffect(() => {
  mystery.value = store.getters['mystery/GET_ITEM']({ id: route.params.id })
})

onMounted(() => {

  // console.log(contact.value)
  watch(() => formMystery.value.v$, (val) => {
    v$.value = val
  })
})

const updateItem = () => {
  console.log('updateItem')
  store.dispatch('mystery/updateItem', {
    id: route.params.id,
    payload: mystery.value
  }).then(() => {
    router.push('/mysteries')
  })
}

const deleteItem = () => {
  console.log('deleteItem')
  store.dispatch('mystery/deleteItem', {
    id: route.params.id
  }).then(() => {
    router.push('/mysteries')
  })
}

const upd = (val) => (mystery.value = val)

</script>
<template>
  <div>
    <!-- <pre>{{ contact }}</pre> -->
    <!-- <button @click="tmp">tmp</button> -->
    <Content>
      <template #header-controls>
        <div>
          <button @click="deleteItem">Delete</button>
        </div>
      </template>
      <template #title>
        <div class="">
          <h3 class=""><span v-if="mystery">{{ getMysteryTitle(mystery) }}</span></h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Информация о мистерии</p>
        </div>
      </template>
      <template #content>
        <div class="mx-auto md:w-1/2">
          <Mystery ref="formMystery" :value="mystery" @input="upd" />
          <div class="mt-5">
            <button class="btn btn-primary" :disabled="v$.$invalid" @click="updateItem">Обновить</button>
          </div>
        </div>
      </template>
    </Content>
  </div>
</template>